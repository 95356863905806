
.nav {
    position: absolute;
    width: 100%;
    z-index: 100;
	padding: 18px 0;
	background: transparent;
    color: $brand-white;

	@media screen and (max-width: $screen-sm) {
		padding: 20px 0;
	}
	#logo {
		margin: 0;
		padding: 0;

      a {
        display: inline-block;
        width: 150px;
        height: 82px;
        text-indent: -9999px;
        background: url('../images/lechocolat-logo-neg.png');
        background-size: 150px 82px;
        background-repeat: no-repeat;
        transform: rotate(-5deg);
        text-transform: uppercase;

        @include transition(.4s);

        &:hover, &:focus {
          transform: rotate(-3deg) scale(1.1);
        }
      }
	}
	a {
		padding: 5px 10px;
		color: $brand-primary;
	}
	.menu-1, .menu-2 {
      padding: 15px 0;

      @media screen and (max-width: $screen-sm ) {
          display: none;
      }
	}
	.menu-2{
      ul {
        list-style: none;

        li {
		  &.shopping-cart{
				float: right;
                position: relative;
                color: $brand-gray;

                .dropdown {
                  position: absolute;
                  visibility: hidden;
                  opacity: 0;
                  font-size: 15px;
                  width: 280px;
                  box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.3);
                  z-index: 1002;
                  top: 100px;
                  right: 0;
                  text-align: left;
                  background: $brand-white;
                  padding: 20px;
                  border-radius: 10px;
                  @include transition(.3s);

                  &:before {
                      bottom: 100%;
                      right: 20px;
                      border: solid transparent;
                      content: " ";
                      height: 0;
                      width: 0;
                      position: absolute;
                      pointer-events: none;
                      border-bottom-color: $brand-white;
                      border-width: 10px;
                      margin-right: -10px;
                  }
                  .total {
                    margin-bottom: 0;
                    padding: 10px 0;
                  }
                  .buttons {
                    margin-bottom: 0;
                    a {
                       @extend .btn;
                       @extend .btn-block;
                       @extend .btn-primary;
                    }
                  }
              }

              &:hover {
                .dropdown {
                  visibility: visible;
                  opacity: 1;
                  top: 32px;
                }
              }

              a {
                  &.cart{
                      color: $brand-white;
                      span{
                          position: relative;
                          small{
                              position: absolute;
                              top: -15px;
                              right: -15px;
                              width: 20px;
                              height: 20px;
                              padding: 5px 6px;
                              line-height: 1;
                              font-size: 12px;
                              background: $brand-primary;
                              color: rgba(255,255,255,1);
                              border-radius: 100%;
                          }
                      }
                  }
              }
            }
		}
      }
	}
    .menu-1 {
      ul {
          padding: 0;
          margin: 0;

          li {
              display: inline-block;
              padding: 0;
              margin: 0;
              list-style: none;
              margin: 0 10px;

              &:nth-child(odd) a { transform: rotate(2deg) ; }
              &:nth-child(even) a { transform: rotate(-2deg) ; }
              &:last-child a { transform: rotate(-1deg) ; }

              a {
                  display: inline-block;
                  font-family: $headings-font-family;
                  font-size: 15px;
                  font-weight: 800;
                  padding: 5px 10px;
                  border: 3px solid $brand-white;
                  border-radius: 10px;
                  text-transform: uppercase;
                  color: $brand-white;
                  @include transition(.5s);

                  &:hover, &:focus, &:active {
                      color: $brand-primary;
                      border-color: $brand-primary;
                  }
              }
              &.has-dropdown {
                  position: relative;
                  .dropdown {
                      width: 150px;

                      box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
                      z-index: 1002;
                      visibility: hidden;
                      opacity: 0;
                      position: absolute;
                      top: 40px;
                      left: 0;
                      text-align: left;
                      background: $brand-black;
                      padding: 20px;
                      border-radius: 4px;
                      @include transition(.0s);
                      &:before {
                          bottom: 100%;
                          left: 40px;
                          border: solid transparent;
                          content: " ";
                          height: 0;
                          width: 0;
                          position: absolute;
                          pointer-events: none;
                          border-bottom-color: #000;
                          border-width: 8px;
                          margin-left: -8px;
                      }

                      li {
                          display: block;
                          margin-bottom: 7px;
                          &:last-child {
                              margin-bottom: 0;
                          }
                          a {
                              padding: 2px 0;
                              display: block;
                              color: lighten($brand-black, 60%);
                              line-height: 1.2;
                              text-transform: none;
                              font-size: 15px;
                              &:hover {
                                  color: $brand-white;
                              }
                          }
                      }
                  }
                  &:hover, &:focus {
                      a {
                          color: $brand-black;
                      }
                      .dropdown {
                      }
                  }
              }
              &.active {
                  > a {
                      color: $brand-primary;
                      border-color: $brand-primary;
                  }
              }
              .form-group{
                  border: 1px solid red;
                  width: 100%;
              }
          }
      }
    }
}




// Burger Menu
.nav-toggle {
  width:25px;
  height:25px;
  cursor: pointer;
  text-decoration: none;
  &.active i {
		&::before, &::after {
			background: $brand-white;
		}
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  display: inline-block;
	  width: 25px;
	  height: 2px;
	  color: #252525;
	  font: bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: #252525;
	  transition: all .2s ease-out;

      &::before, &::after {
	  	  content:'';
		  width: 25px;
		  height: 2px;
		  background: #252525;
		  position: absolute;
		  left:0;
		  transition:all .2s ease-out;
	  }
  }
  &.nav-white {
  	> i {
  		color:$brand-white;
  		background: $brand-white;
  		&::before, &::after {
  			background: $brand-white;
  		}
  	}
  }
}

.nav-toggle i::before {
  top: -7px;
}
.nav-toggle i::after {
  bottom: -7px;
}
//.nav-toggle:hover i::before {
//  top: -10px;
//}
//.nav-toggle:hover i::after {
//  bottom: -10px;
//}
.nav-toggle.active i {
	background: transparent;
}
.nav-toggle.active i::before {
	background: $brand-white;
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.nav-toggle.active i::after {
	background: $brand-white;
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}
.nav-toggle {
  position: absolute;
  right: 0px;
  top: 10px;
  // top: 100px;
  // left: -100px;;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  // background: #f86942;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none!important;
  @media screen and (max-width: $screen-sm) {
  	display: block;
  }
}
