// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

// Fonts
$font-primary: 'Sanchez', 'Playfair Display', serif;
$font-secondary: 'Poppins', 'Helvetica Neue', Helvetica, sans-serif;

$font-family-sans-serif: $font-secondary;
$headings-font-family: $font-primary;
$headings-font-weight: 400;



// Overrides
$grid-gutter-width: 40px !default;
$border-radius-base:  0 !default;
//$padding-base-vertical: 14px !default;

$brand-primary:  #d9cbba !default;
$brand-secondary: #a4917d !default;


// Colors
$brand-white: #fff;
$brand-black: #23232c;
$brand-darker: #333;
$brand-gray: #444;
$brand-lighter: #efefef;
$brand-body-color: #818892;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #3b3d40;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;
