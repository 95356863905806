/* ==========================================================================
   Mixins
   ========================================================================== */
@mixin translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}
@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}

@mixin flex() {
	display: -webkit-box;
  	display: -moz-box;
  	display: -ms-flexbox;
  	display: -webkit-flex;
  	display: flex;
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
}

/* ==========================================================================
   Grayscale
   ========================================================================== */

.grayscale {
   -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
  filter: grayscale(100%);
}


/* ==========================================================================
   Global
   ========================================================================== */
body {
	font-family: $font-secondary;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.7;
	color: #828282;
	background: #fff;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover, &:active, &:focus {
		color: $brand-primary;
		outline: none;
		text-decoration: none;
	}
}
p {
	margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, figure {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 20px 0;
}

.uppercase {
	font-size: 14px;
	color: $brand-black;
	margin-bottom: 10px;
	font-weight: 700;
	text-transform: uppercase;
}

/* ==========================================================================
   Selection
   ========================================================================== */
::-webkit-selection {
  color: $brand-white;
  background: $brand-primary;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}
::selection {
  color: $brand-white;
  background: $brand-primary;
}

/* ==========================================================================
   Animate Waypoints
   ========================================================================== */
.animated-fast {
  animation-duration: .5s;
  animation-fill-mode: both;
}



//#header,
//#counter,
//.bg {
//	background-size: cover;
//	background-position: top center;
//	background-repeat: no-repeat;
//	position: relative;
//}
//.bg {
//	background-size: cover;
//	background-position: center center;
//	position: relative;
//	width: 100%;
//	float: left;
//	position: relative;
//}

// .video {
// 	// height: 450px;
// 	overflow: hidden;
// 	// @include border-radius(7px);
// 	@media screen and (max-width: $screen-md){
// 		height: 450px;
// 	}
// 	a {
// 		z-index: 1001;
// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		margin-top: -45px;
// 		margin-left: -45px;
// 		width: 90px;
// 		height: 90px;
// 		display: table;
// 		text-align: center;
// 		background: $brand-white;

// 		-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
// 		-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
// 		box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
// 		@include border-radius(50%);

// 		i {
// 			text-align: center;
// 			display: table-cell;
// 			vertical-align: middle;
// 			font-size: 40px;

// 		}
// 	}
// 	.overlay {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		background: rgba(0, 0, 0, .5);
// 		@include transition(.5s);
// 	}
// 	&:hover {
// 		.overlay {
// 			background: rgba(0, 0, 0, .7);
// 		}
// 		a {
// 			// position: relative;
// 			-webkit-transform: scale(1.2);
// 			-moz-transform: scale(1.2);
// 			-ms-transform: scale(1.2);
// 			-o-transform: scale(1.2);
// 			transform: scale(1.2);

// 		}
// 	}
// }

//#counter {
//	height: 500px;
//	.display-t,
//	.display-tc {
//		height: 500px;
//		display: table;
//		width: 100%;
//	}
//	@media screen and (max-width: $screen-md) {
//		height: inherit;
//		padding: 7em 0;
//		.display-t,
//		.display-tc {
//			height: inherit;
//		}
//	}
//	@media screen and (max-width: $screen-sm) {
//		height: inherit;
//		padding: 7em 0;
//		.display-t,
//		.display-tc {
//			height: inherit;
//		}
//	}
//}

//.cover {
//	height: 800px;
//
//	background-size: cover;
//	background-position: top center;
//	background-repeat: no-repeat;
//	position: relative;
//	float: left;
//	width: 100%;
//	.overlay {
//		z-index: 0;
//		position: absolute;
//		bottom: 0;
//		top: 0;
//		left: 0;
//		right: 0;
//		background: rgba($brand-black,.2);
//	}
//	> .container {
//		position: relative;
//		z-index: 10;
//	}
//	@media screen and (max-width: $screen-sm) {
//		height: 600px;
//	}
//	.display-t,
//	.display-tc {
//		z-index: 9;
//		height: 900px;
//		display: table;
//		width: 100%;
//		@media screen and (max-width: $screen-sm) {
//			height: 600px;
//		}
//	}
//
//	&.cover-sm {
//		height: 600px;
//		@media screen and (max-width: $screen-sm) {
//			height: 400px;
//		}
//		.display-t,
//		.display-tc {
//			height: 600px;
//			display: table;
//			width: 100%;
//			@media screen and (max-width: $screen-sm) {
//				height: 400px;
//			}
//		}
//	}
//}

//.about-content{
//	margin-bottom: 7em;
//	img{
//		border: 1px solid rgba($brand-black,.05);
//		padding: 10px;
//	}
//	.desc{
//		margin-bottom: 3em;
//	}
//}
//.staff {
//	text-align: center;
//	margin-bottom: 30px;
//	img {
//		width: 170px;
//		margin-bottom: 20px;
//		@include border-radius(50%);
//	}
//	h3 {
//		font-size: 24px;
//		margin-bottom: 5px;
//	}
//	p {
//		margin-bottom: 30px;
//	}
//	.role {
//		color: lighten($brand-black, 75%);
//		margin-bottom: 30px;
//		font-weight: normal;
//		display: block;
//	}
//}

//
//
//.contact-info {
//	ul {
//		padding: 0;
//		margin: 0;
//		li {
//			padding: 0 0 0 40px;
//			margin: 0 0 30px 0;
//			list-style: none;
//			position: relative;
//			&:before {
//				color: $brand-primary;
//				position: absolute;
//				left: 0;
//				top: .05em;
//				font-family: 'icomoon';
//			}
//			&.address {
//				&:before {
//					font-size: 30px;
//					content: "\e9d1";
//				}
//			}
//			&.phone {
//				&:before {
//					font-size: 23px;
//					content: "\e9f4";
//				}
//			}
//			&.email {
//				&:before {
//					font-size: 23px;
//					content: "\e9da";
//				}
//			}
//			&.url {
//				&:before {
//					font-size: 23px;
//					content: "\e9af";
//				}
//			}
//		}
//	}
//}


// form {
// 	label {
// 		font-weight: normal!important;
// 	}
// }

//
//#header,
//#counter,
//.cover {
//	.display-tc {
//		display: table-cell!important;
//		vertical-align: middle;
//		h1, h2 {
//			margin: 0;
//			padding: 0;
//			color: rgba(255,255,255,1);
//		}
//		h1 {
//			margin-bottom: 0px;
//			font-size: 60px;
//			line-height: 1.5;
//			text-transform: uppercase;
//			font-weight: 700;
//			@media screen and (max-width: $screen-sm) {
//				font-size: 40px;
//			}
//		}
//		h2 {
//			font-size: 20px;
//			line-height: 1.5;
//			margin-bottom: 30px;
//			font-family: $font-secondary;
//			a{
//				color: rgba($brand-white,.7);
//				border-bottom: 1px solid rgba($brand-white,.6);
//			}
//		}
//		.btn {
//			padding: 15px 30px;
//			background: $brand-primary!important;
//			color: $brand-white;
//			border: none!important;
//			font-size: 18px;
//			text-transform: uppercase;
//			&:hover {
//				background: $brand-primary!important;
//				-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75)!important;
//				-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75)!important;
//				box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75)!important;
//			}
//		}
//		.form-inline {
//			.form-group {
//				width: 100%!important;
//				margin-bottom: 10px;
//				.form-control {
//					width: 100%;
//					background: $brand-white;
//					border: none;
//				}
//			}
//		}
//	}
//}

//#counter {
//	text-align: center;
//	.counter {
//		font-size: 50px;
//		margin-bottom: 10px;
//		color: $brand-black;
//		font-weight: 100;
//		display: block;
//	}
//	.counter-label {
//		margin-bottom: 0;
//		text-transform: uppercase;
//		color: rgba(0,0,0,.5);
//		letter-spacing: 1px;
//		font-family: $font-primary;
//	}
//
//	.feature-center {
//		padding: 40px 20px;
//		background: rgba($brand-white,.8);
//		@media screen and (max-width: $screen-sm) {
//			margin-bottom: 50px;
//		}
//	}
//	.icon {
//		width: 70px;
//		height: 70px;
//		text-align: center;
//		background: $brand-primary;
//		-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
//		-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
//		box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
//		margin-bottom: 20px;
//		i {
//			height: 70px;
//			color: $brand-white;
//			&:before {
//				display: block;
//				text-align: center;
//				margin-left: 3px;
//			}
//		}
//	}
//}


//.bg-section{
//	background: rgba($brand-black,.05);
//}

//#services,
//#started,
//#testimonial,
//#started,
//#product,
//#about,
//#contact {
//	padding: 7em 0;
//	clear: both;
//	@media screen and (max-width: $screen-sm) {
//		padding: 3em 0;
//	}
//}





// PRODUCT
//.product{
//	margin-bottom: 40px;
//	.product-grid{
//		background-size: cover;
//		background-position: top center;
//		background-repeat: no-repeat;
//		position: relative;
//		height: 350px;
//		margin-bottom: 20px;
//		@include transition(.3s);
//		.sale{
//			position: absolute;
//			top: 10px;
//			left: 10px;
//			padding: 10px;
//			z-index: 100;
//			background: $brand-primary;
//			color: $brand-white;
//			@include border-radius(50%);
//		}
//		.inner{
//			display: table;
//			height: 350px;
//			width: 100%;
//			opacity: 0;
//			background: rgba($brand-black,.3);
//			@include transition(.3s);
//			@media screen and (max-width: $screen-sm){
//				opacity: 1;
//				background: transparent;
//			}
//			p{
//				display: table-cell;
//				vertical-align: middle;
//				.icon{
//					padding: 7px 10px;
//					color: $brand-white;
//					background: $brand-primary;
//					&:hover,&:focus{
//						color: $brand-primary;
//						background: $brand-white;
//					}
//				}
//			}
//		}
//	}
//	.desc{
//		h3{
//			font-size: 14px;
//			letter-spacing: 1px;
//			text-transform: uppercase;
//			margin-bottom: 5px;
//			font-weight: bold;
//			line-height: 24px;
//			a{
//				color: $brand-black;
//			}
//		}
//		.price{
//			letter-spacing: 2px;
//			text-transform: uppercase;
//		}
//	}
//	&:hover, &:focus{
//		.product-grid{
//			box-shadow: 0px 10px 24px -7px rgba(0,0,0,0.35);
//		}
//		.inner{
//			opacity: 1;
//		}
//		.desc{
//			h3{
//				a{
//					color: $brand-primary;
//				}
//			}
//		}
//	}
//}
//
//.product-carousel{
//	margin-bottom: 7em;
//}
//
//.tabs {
//	.tab-nav {
//		clear: both;
//		margin: 0 0 3em 0;
//		padding: 3px;
//		float: left;
//		width: 100%;
//		background: lighten($brand-black, 95%);
//		@include border-radius(4px);
//		li {
//			float: left;
//			margin: 0;
//			padding: 0;
//			width: 33.33%;
//			text-align: center;
//			list-style: none;
//			@include inline-block;
//			.icon {
//				font-size: 30px;
//			}
//			a {
//				padding: 20px;
//				width: 100%;
//				float: left;
//				@include border-radius(4px);
//				color: lighten($brand-black, 70%);
//				&:hover {
//					color: lighten($brand-black, 30%);
//				}
//			}
//			&.active {
//				a {
//					background: $brand-white;
//					color: $brand-primary;
//				}
//			}
//		}
//	}
//	.tab-content-wrap {
//		clear: both;
//		position: relative;
//		top: 70px;
//		.tab-content {
//			position: absolute;
//			top: 0;
//			left: 0;
//			width: 100%;
//			opacity: 0;
//			visibility: hidden;
//			&.active {
//				opacity: 1;
//				visibility: visible;
//			}
//			.price{
//				letter-spacing: 2px;
//				border: 2px solid $brand-primary;
//				display: inline-block;
//				margin-bottom: 20px;
//				padding: 7px 20px 10px 20px;
//			}
//		}
//		ul{
//			padding: 0;
//			margin: 0;
//			margin-bottom: 3em;
//			li{
//				list-style: none;
//				margin-bottom: 20px;
//				position: relative;
//				padding-left: 30px;
//				&:before{
//					font-family: 'icomoon';
//					position: absolute;
//					top: 0;
//					left: 0;
//					content: '\e116';
//				}
//			}
//		}
//		.feed{
//			width: 100%;
//			float: left;
//			margin-bottom: 7em;
//			>div{
//				width: 100%;
//				float: left;
//				margin-bottom: 1em;
//				blockquote{
//					border-left: 0;
//					padding: 20px;
//					background: rgba($brand-black,.04);
//				}
//				h3{
//					font-family: $font-secondary;
//					font-size: 18px;
//					display: inline-block;
//					padding-right: 20px;
//				}
//				.rate{
//					color: #ffba00;
//				}
//			}
//		}
//	}
//	.icon-xlg {
//		font-size: 400px;
//		margin-bottom: 30px;
//		text-align: center;
//		@media screen and (max-width: $screen-sm) {
//			font-size: 300px;
//		}
//		@media screen and (max-width: $screen-xs) {
//			font-size: 200px;
//		}
//		i {
//			color: $brand-primary;
//		}
//	}
//}

/* ==========================================================================
   Testimonials
   ========================================================================== */
//#testimonial {
//	background: #efefef;
//	.testimony-slide{
//		// display: none;
//		text-align: center;
//		span{
//			font-size: 12px;
//			text-transform: uppercase;
//			letter-spacing: 2px;
//			font-weight: 700;
//			display: block;
//		}
//
//		figure{
//			margin-bottom: 10px;
//			@include inline-block;
//
//			img {
//				width: 100px;
//				border-radius: 50%;
//			}
//		}
//
//		blockquote{
//			border: none;
//			margin: 30px auto;
//			width: 50%;
//			position: relative;
//			padding: 0;
//			@media screen and (max-width: $screen-md) {
//				width: 100%;
//			}
//
//		}
//
//	}
//
//	.arrow-thumb{
//		position: absolute;
//		top: 40%;
//		display: block;
//		width: 100%;
//
//		a{
//			font-size: 32px;
//			color: #dadada;
//
//			&:hover, &:focus, &:active{
//				text-decoration: none;
//			}
//		}
//
//	}
//}

/* ==========================================================================
   Google Map
   ========================================================================== */
#gmap {

  .row  {
    margin: 0;
    background: $brand-lighter;
  }
  .map {
    padding: 0;
    background: $brand-lighter;
  }
  .address {
    padding: 80px;
  }
}
.gmap {
	width: 100%;
	height: 400px;
    padding: 0;
    img {
       max-width: inherit !important;
    }
}


/* ==========================================================================
   Go to Top
   ========================================================================== */
.gototop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	@include transition(.5s);
	&.active {
		opacity: 1;
		visibility: visible;
	}
	a {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.5);
		color: $brand-white;
		text-align: center;
		border-radius: 4px;
		i {
			height: 50px;
			display: table-cell;
			vertical-align: middle;

		}
		&:hover, &:active, &:focus {
			text-decoration: none;
			outline: none;
		}
	}
}


/* ==========================================================================
   Buttons
   ========================================================================== */
.btn {
	margin-right: 4px;
	margin-bottom: 4px;
	font-family: $font-family-sans-serif;
    text-transform: uppercase;
	font-size: 16px;
	font-weight: 400;
	@include transition(.5s);
	padding: 8px 20px;
	&.btn-md {
		padding: 8px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}
.btn-primary {
	background: $brand-primary;
	color: $brand-white;
	border: 2px solid $brand-primary;
	&:hover, &:focus, &:active {
		background: lighten($brand-primary, 5%)!important;
		border-color: lighten($brand-primary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
.btn-success {
	background: $brand-success;
	color: $brand-white;
	border: 2px solid $brand-success;
	&:hover, &:focus, &:active {
		background: darken($brand-success, 5%)!important;
		border-color: darken($brand-success, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-success;
		border: 2px solid $brand-success;
		&:hover, &:focus, &:active {
			background: $brand-success;
			color: $brand-white;
		}
	}
}
.btn-info {
	background: $brand-info;
	color: $brand-white;
	border: 2px solid $brand-info;
	&:hover, &:focus, &:active {
		background: darken($brand-info, 5%)!important;
		border-color: darken($brand-info, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-info;
		border: 2px solid $brand-info;
		&:hover, &:focus, &:active {
			background: $brand-info;
			color: $brand-white;
		}
	}
}
.btn-warning {
	background: $brand-warning;
	color: $brand-white;
	border: 2px solid $brand-warning;
	&:hover, &:focus, &:active {
		background: darken($brand-warning, 5%)!important;
		border-color: darken($brand-warning, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-warning;
		border: 2px solid $brand-warning;
		&:hover, &:focus, &:active {
			background: $brand-warning;
			color: $brand-white;
		}
	}
}
.btn-danger {
	background: $brand-danger;
	color: $brand-white;
	border: 2px solid $brand-danger;
	&:hover, &:focus, &:active {
		background: darken($brand-danger, 5%)!important;
		border-color: darken($brand-danger, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-danger;
		border: 2px solid $brand-danger;
		&:hover, &:focus, &:active {
			background: $brand-danger;
			color: $brand-white;
		}
	}
}

.btn-outline {
	background: none;
	border: 2px solid lighten($brand-black, 50%);
	font-size: 16px;
	@include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}

.btn.with-arrow {
	position: relative;
	@include transition(.3s);
	i {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -8px;
		@include transition(.2s);
	}
	&:hover {
		padding-right: 50px;
		i {
			color: $brand-white;
			right: 18px;
			visibility: visible;
			opacity: 1;
		}
	}
}


/* ==========================================================================
   Row
   ========================================================================== */
.row-pb-md {
	padding-bottom: 4em!important;
}
.row-pb-sm {
	padding-bottom: 2em!important;
}


/* ==========================================================================
   Loader
   ========================================================================== */
.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
    text-align: center;
	background: $body-bg;

    &:before {
      display: none!important; //prevent woocommerce ajax loader
    }
}

@keyframes ball-pulse-sync {
  33% { transform: translateY(10px); }
  66% { transform: translateY(-10px); }
  100% { transform: translateY(0); } }

.ball-pulse-sync > div:nth-child(1) {
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(2) {
  animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(3) {
  animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}

.ball-pulse-sync {
  position: relative;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}
.ball-pulse-sync > div {
  background-color: lighten($brand-primary,10%);
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  display: inline-block;
}

/* ==========================================================================
   Animate
   ========================================================================== */
.animate-box {
	.js & {
		opacity: 0;
	}
}


/* ==========================================================================
   Quick View
   ========================================================================== */

.mfp-zoom-in .mfp-with-anim {
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.2s ease-in-out;
	transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
	opacity: 0;
	transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
	opacity: 1;
	filter: alpha(opacity=100);
	transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
	transform: scale(0.8);
	opacity: 0;
	filter: alpha(opacity=0);
}
.mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
	filter: alpha(opacity=0);
}


.quick_view_content {
	background: $body-bg;
	padding: 40px 40px 30px;
	max-width: 900px;
    width: 80%;
	text-align: left;
	margin: 30px auto;
	position: relative;
	font-weight: 400;

    @media (max-width: $screen-md-min){
      padding: 0;
    }
}

.quick-view_images {
	width: 60%;
	float: left;
	display: inline;
	margin: -40px 0 -30px -40px;

    @media (max-width: $screen-md-min){
        display: block;
		float: inherit;
		margin: 0;
		width: auto;
    }

    img{
      border-radius: 0!important;
      box-shadow: inherit!important;
      display: block;

      @media (max-width: $screen-md-min){
		width: 100%;
      }
    }
}
.quick-view_summary {
	float: right;
	width: 40%;
	display: inline;

     @media (max-width: $screen-md-min){
        display: block;
	    float: inherit;
	    padding: 30px 15px 0;
	    width: auto;
     }

    .quick-view_product_title{
        font-size: 27px;
        font-family: $font-family-sans-serif;
        color: $brand-black;
        font-weight: 700;
        margin: 0 0 20px;
        line-height: 1.2;
    }
    .quick-view_product_price{
        margin: 0 0 25px;
        display: block!important;
        .amount{
          font-size: 20px!important;
          line-height: 1.2!important;
          color: $brand-primary;
          font-weight: 600!important;
        }
    }
    p {
        font-size: 14px;
        font-weight: normal;
        margin: 0 0 25px;
        line-height: 1.6;
    }
    form.cart {
        overflow: hidden;
        margin: 35px 0;
    }

    .woocommerce-product-rating {
      margin-bottom: 20px;
    }

    .product_meta {
        padding-top: 0;
        margin-bottom: 20px;

        a {
          font-family: $headings-font-family;
          color: $brand-black;
          border: 3px solid $brand-black;
          border-radius: 10px;
          padding: 2px 5px;
          display: inline-block;
          margin-bottom: 5px;
          transition: .3s;

          &:hover {
            border-color: $brand-primary;
            color: $brand-primary;
          }
        }
    }


    .quantity {
        float: left;
        display: inline;
        width: auto;
        min-width: inherit!important;
        border: none;
        padding: 0;
        margin: 0;

		.qty {
            @extend .form-control;
            box-shadow: none !important;
            width: 50px;
            text-align: center;
            height: 40px;
            padding: 1px;
            font-size: 24px;
            font-weight: bold;
            border: 0 none;
            border-bottom: 4px solid $brand-lighter;
            border-radius: 0;
            margin-right: 15px;
		}

        .minus,
        .plus {
            display: none!important;
        }

	}

    .button {
        border: none!important;
        box-shadow: none!important;
        height: 35px!important;
        padding: 0 15px!important;
        font-weight: 400!important;
        text-shadow: none!important;
        background: #47C285!important;
        color: #ffffff!important;
        text-shadow: none!important;
        text-transform: uppercase;
        letter-spacing: 0;
        margin: 0!important;
        border-radius: 3px!important;
        float: left!important;
        display: inline!important;
        width: auto!important;
        font-size: 14px!important;
        text-decoration: none!important;
        transition: all 0.2s linear;

        &:hover {
            opacity: 0.8!important;
        }
        &:focus{
            outline: 0;
        }
  }
}


/* ==========================================================================
   Sharer
   ========================================================================== */
#sharer {
  ul {
    li {
      a {
        color: darken($brand-lighter, 10%);
        transition: .3s;
        &:hover, &:focus {
          color: $brand-primary;
        }
      }
    }
  }
}

