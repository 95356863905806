/* ==========================================================================
   Single
   ========================================================================== */

.single-post {
  #sharer {
    margin-bottom: 80px;
  }

  h2, h3 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    color: $brand-black;
  }

  #comment-section {
    border-top: 1px solid $brand-lighter;
  }
}
