.half-left,
.half-right,
.full-width,
.three-columns {
  background-size:cover;
  background-repeat: none;
  background-position: 0 0;

  &.background {

    h2,h3,h4,h5 {
      color: $brand-white;
    }

    .section-heading {
      color: $brand-white;
    }

    .item {
      color: $brand-white;
    }

  }
}


.three-columns {
  .section-heading {
    margin-bottom: 50px;
  }
  .item {
    text-align: center;
    margin-bottom: 30px;

    @media(min-width:$screen-sm-min) {
      margin-bottom: 0;
    }

    .item-image {
      max-width: 200px;
      display: inline-block;
      margin-bottom: 20px;
    }

    h3 {
      font-family: $font-family-sans-serif;
      font-weight: 700;

    }
  }
}
