.sidebar {

  .widget {
    margin-bottom: 50px;

    h3 {
      font-size: 20px;
      text-transform: uppercase;
      color: $brand-secondary;
    }

    &.widget_product_search {
      .woocommerce-product-search {
        border-bottom: 3px solid #efefef;
        position: relative;
      }
      input[type=search] {
        border: 0;
        outline: 0;
        font-size: 14px;
        height: 30px;
        display: inline-block;
        box-shadow: none;
        background: transparent;
        width: 90%;
      }
      button {
        position: absolute;
        outline: 0;
        border: 0;
        right: 0;
        bottom: 3px;
        box-shadow: none;
        display: inline-block;
        background: transparent;
        width: 30px;
        height: 30px;
        font-size: 18px;
        color: $brand-secondary;
        font-weight: 300;
      }
    }


    &.widget_product_categories {

      ul {
        list-style: none;
        padding: 0;

        li {

          &:before {
            content: "\f105";
            font-family: icomoon!important;
            font-weight: 300;
            margin-right: 10px;
          }

          a {
            font-size: 14px;
            font-weight: 500;
            color: $brand-black;
            transition: .3s;

            &:hover, &:focus {
              color: $brand-secondary;
            }
          }
        }
      }
    }
    &.widget_product_tag_cloud {
      a {
        display: inline-block;
        font-weight: 14px;
        font-family: $headings-font-family;
        border: 2px solid $brand-black;
        color: $brand-black;
        border-radius: 10px;
        padding: 2px 5px;
        margin-bottom: 5px;
        transition: .3s;

        &:hover, &:focus, &.active {
          border-color: $brand-secondary;
          color: $brand-secondary;
        }
      }
    }
  }
}
