/**
 * WooCommerce CSS Variables
 */

$woocommerce:   	$brand-primary;
$green:         	#7ad03a;
$red:           	#a00;
$orange:        	#ffba00;
$blue:          	#2ea2cc;

$primary:           $brand-primary;                                    // Primary colour for buttons (alt)
$primarytext:       #fff;    // Text on primary colour bg

$secondary:         $brand-secondary;    // Secondary buttons
$secondarytext:     #fff;   // Text on secondary colour bg

$highlight:         $brand-primary;               // Prices, In stock labels, sales flash
$highlightext:      #fff;  // Text on highlight colour bg

$contentbg:         $body-bg;                                       // Content BG - Tabs (active state)
$subtext:           $text-color;                                       // small, breadcrumbs etc


@keyframes slide-in {
    0% {
        transform: translateX(800px);
        width: 100px;
        max-height: 75px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.2)
    }

    50% {
        transform: translateX(-320px);
        width: 100px;
        max-height: 75px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.2)
    }

    75% {
        transform: translateX(-320px);
        width: 320px;
        max-height: 75px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.2)
    }

    100% {
        transform: translateX(-320px);
        width: 320px;
        max-height: 500px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.9)
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(-320px);
        width: 320px;
        max-height: 500px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.9)
    }

    50% {
        transform: translateX(-320px);
        width: 320px;
        max-height: 75px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.2)
    }

    75% {
        transform: translateX(-320px);
        width: 100px;
        max-height: 75px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.2)
    }

    100% {
        transform: translateX(800px);
        width: 100px;
        max-height: 75px;
        box-shadow: 0px 15px 25px -20px rgba(0, 0, 0, 0.2)
    }
}



/**
 * Main WooCommerce styles
 */
.woocommerce {

    // Fix for homepage product grid
    &.columns-4 {

      @media(min-width:$screen-sm-min) {
        ul.products li.product {
          width: 22.05%;
          margin-right: 3.8%;

          &.last {
            margin-right: 0;
          }
        }
      }
    }

     // Woocommerce page
      .col2-set {
        margin: 30px 0;

        .address {
          padding: 15px;
          background-color: lighten($text-color,60%);
        }
      }

    // Bootstrap input extend
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=password],
    .input-text,
    textarea {
      @extend .form-control;
      box-shadow: none !important;
    }

	a.remove {
		display: block;
		font-size: 1.5em;
		height: 1em;
		width: 1em;
		text-align: center;
		line-height: 1;
		border-radius: 50%;
		color: $brand-lighter !important;
		text-decoration: none;
		font-weight: 300 !important;
		border: 0;

		&:hover {
			color: $brand-primary !important;
			background: transparent !important;
		}
	}

    .cart-empty {
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      color: $brand-black;
    }
    .return-to-shop {
      text-align: center;
    }

    .woocommerce-message,
	.woocommerce-error,
	.woocommerce-info {
//		padding: 1em 2em 1em 3.5em !important;
//		margin: 0 0 2em !important;
//		position: relative;
//		background-color: lighten($text-color,50%);
//        color: #fff;
//		border-top: 3px solid $text-color;
//		list-style: none outside !important;
//		@include clearfix();
//		width: auto;
//		word-wrap: break-word;
//        line-height: 2em;

        background: $brand-primary;
        box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.2);
        border: none;
        position: fixed;
        top: 150px;
        left: 100%;
        width: 200px;
        min-height: 100px;
        max-height: 100px;
        display: block;
        margin: 0 !important;
        padding: 0 !important;
        z-index: 9999;
        overflow: hidden;
        animation: slide-in, slide-out;
        animation-duration: 1.5s, 1.5s;
        animation-delay: .5s, 4.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
        transform: translateX(0);
        font-weight: bold;
        color: #FFF;
        font-size: 16px;

		&:before {
          font-family: "WooCommerce";
          content: "\e028";
          display: inline-block;
          position: absolute;
          color: #fff;
          top: 1em;
          left: 1.5em;
		}

		.button {
			float: right;
		}

		li {
			list-style: none outside !important;
			padding-left: 0 !important;
			margin-left: 0 !important;
		}
	}

	.woocommerce-message {
		border-top-color: transparent;
        background-color: $brand-primary;

		&:before {
			content: "\e015";
		}
	}

	.woocommerce-info {
		border-top-color: $brand-info;
        background-color: $brand-primary;

		&:before {
		}
	}

	.woocommerce-error {
		border-top-color: $brand-danger;
        background-color: $brand-primary;

		&:before {
			content: "\e016";
		}
	}

	small.note {
		display: block;
		color: $subtext;
		font-size: .857em;
		margin-top: 10px;
	}

	.woocommerce-breadcrumb {
		@include clearfix();
		margin: 0 0 1em;
		padding: 0;
		font-size: 0.92em;
		color: $subtext;

		a {
			color: $subtext;
		}
	}

	.quantity {
      display: inline-block;
		.qty {
            @extend .form-control;
            box-shadow: none !important;
            width: 50px;
            text-align: center;
            height: 40px;
            padding: 1px;
            font-size: 24px;
            font-weight: bold;
            border: 0 none;
            border-bottom: 4px solid $brand-lighter;
            border-radius: 0;
            margin-right: 15px;
		}
	}

	/**
	 * Product Page
	 */
	div.product {
		margin-bottom: 0;
		position: relative;

		.product_title {
            font-family: $font-family-sans-serif;
            font-size: 54px;
            font-weight: 700;
			clear: none;
			margin-top: 0;
			padding: 0;
		}

		span.price,
		p.price {
			color: $highlight;
			font-size: 36px;
            margin: 0 0 20px;

			ins {
				background: inherit;
				font-weight: 700;
			}

			del {
				opacity: 0.5;
			}
		}

        .product_meta {
          padding-top: 0;
          margin-bottom: 20px;

          a {
            font-family: $headings-font-family;
            color: $brand-black;
            border: 3px solid $brand-black;
            border-radius: 10px;
            padding: 2px 5px;
            display: inline-block;
            margin-bottom: 5px;
            transition: .3s;

            &:hover {
              border-color: $brand-primary;
              color: $brand-primary;
            }
          }

          & > span {
            display: block;
            margin-bottom: 5px;

            &.posted_in,
            &.tagged_as {
              & > a {
                @extend .label;
                @extend .label-primary;
              }
            }
          }
        }

      .short-description {
        padding: 20px 0;
        margin: 20px 0;
        border-top: 1px solid lighten($text-color,50%);
        border-bottom: 1px solid lighten($text-color,50%);

        p:last-child {
          margin-bottom: 0; // Fix for spacing
        }
      }

		p.stock {
			font-size: 0.92em;
		}

		.stock {
			color: $highlight;
		}

		.out-of-stock {
			color: red;
		}

		.woocommerce-product-rating {
			margin-bottom: 20px;
		}

		div.images {
			margin-bottom: 2em;

            .woocommerce-main-image {
              position: relative;
              display: block;
              overflow: hidden;

              &:hover {
                .overlay {
                  opacity: 1;
                }
                img {
                  transform: scale(1.1);
                }
              }
            }

			img {
				display: block;
				width: 100%;
				height: auto;
				box-shadow: none;
                transition: all .3s ease-in-out;
			}

			div.thumbnails {
				padding-top: 1em;
			}
		}

		div.summary {
			margin-bottom: 2em;
		}

		div.social {
			text-align: right;
			margin: 0 0 1em;

			span {
				margin: 0 0 0 2px;

				span {
					margin: 0;
				}

				.stButton .chicklets {
					padding-left: 16px;
					width: 0;
				}
			}

			iframe {
				float: left;
				margin-top: 3px;
			}
		}

		.woocommerce-tabs {
            padding: 0 0 20px;
            text-align: center;
            border-top: 1px solid $brand-lighter;
            border-bottom: 1px solid $brand-lighter;

			ul.tabs {
                display: inline-block;
				list-style: none;
				padding: 0 0 0 1em;
				margin: 0 0 1.618em;
				overflow: hidden;
				position: relative;

				li {
					border: 0 none;
					background-color: transparent;
					display: block;
					position: relative;
					z-index: 0;
					border-radius: 0;
					margin: 2px 0;
                    padding: 0;
                    transition: all .3s ease-in-out;

                    @media(min-width: $screen-sm-min) {
                      display: inline-block;
                      width: auto;
                      margin: 0 5px;
                    }

					a {
						display: block;
                        text-transform: uppercase;
                        font-size: 15px;
                        font-family: $headings-font-family;
						font-weight: 300;
					    padding: 10px 15px;
						color: lighten($brand-black, 50%);
					    border: 4px solid transparent;
						text-decoration: none;
                        transition: all .3s ease-in-out;
                        border-radius: none;

                        @media(min-width:$screen-sm-min) {
                          display: inline-block;
                        }

						&:hover {
							text-decoration: none;
							color: $brand-black;
                            background-color: transparent;
                            border-top: 4px solid lighten($brand-primary, 5%) !important;
						}
					}

					&.active {
						z-index: 2;
						//border-bottom-color: $contentbg;
						border: 0 none;

						a {
						    background: transparent;
							color: $brand-black;
							text-shadow: inherit;
						    border-top: 4px solid $brand-primary;

						}

						&:before {
							box-shadow: none;
						}

						&:after {
							box-shadow: none;
						}
					}

					&:before,
					&:after {
						border: 0 none;
						position: absolute;
						bottom: -1px;
						width: 5px;
						height: 5px;
						content: " ";
					}

					&:before {
						left: 0;
						border-bottom-right-radius: 0;
						border-width: 0;
						box-shadow: none;
					}

					&:after {
						right: 0;
						border-bottom-left-radius: 0;
						border-width: 0 ;
						box-shadow: none;
					}
				}

				&:before {
                    display: none !important; // Remove bottom line
					position: absolute;
					content: " ";
					width: 100%;
					bottom: 0;
					left: 0;
					border-bottom: 1px solid darken( $secondary, 10% );
					z-index: 1;
				}
			}

			.panel {
                background-color: $brand-white;
                text-align: center;
                box-shadow: none;
				margin: 0 0 2em;
				padding: 0;

                h2 {
                  font-size: 36px;
                  font-family: $font-family-sans-serif;
                  font-weight: 700;
                }
			}
		}

		p.cart {
			margin-bottom: 2em;
			@include clearfix();
		}

		form.cart {
			margin-bottom: 2em;
			@include clearfix();

			div.quantity {
				float: left;
				margin: 0 4px 0 0;
			}

			table {
				border-width: 0 0 1px 0;

				td {
					padding-left: 0;
				}

				div.quantity {
					float: none;
					margin: 0;
				}

				small.stock {
					display: block;
					float: none;
				}
			}

			.variations {
				margin-bottom: 1em;
				border: 0;
				width: 100%;

				td,
				th {
					border: 0;
					vertical-align: top;
					line-height: 2em;
				}

				label {
                    color: $text-color;
                    font-size: $font-size-base;
					font-weight: 700;
                    line-height: 2em;
				}

				select {
					max-width: 100%;
					min-width: 75%;
					display: inline-block;
					margin-right: 1em;
				}

				td.label {
					padding-right: 1em;
				}
			}

            .woocommerce-variation-price {
                font-size: 24px;
                margin-bottom: 30px;
            }

			.woocommerce-variation-description {
				p {
					margin-bottom: 1em;
				}
			}

			.reset_variations {
				visibility: hidden;
				font-size: 0.83em;
			}

			.wc-no-matching-variations {
				display: none;
			}

			.button {
				vertical-align: middle;
				float: left;
			}

			.group_table {
				td.label {
					padding-right: 1em;
					padding-left: 1em;
				}

				td {
					vertical-align: top;
					padding-bottom: .5em;
					border: 0;
				}
			}
		}
	}

	span.onsale {
		min-height: 1px;
		min-width: 1px;
		padding: 10px 15px;
		font-size: 1em;
		font-weight: 700;
		position: absolute;
		text-align: center;
		line-height: 1.5;
		top: 10px;
		left: 10px;
		margin: 0;
		border-radius: 4px;
		background-color: $highlight;
		color: $highlightext;
		font-size: .857em;
		-webkit-font-smoothing: antialiased;
	}

	/**
	 * Product loops
	 */
	.products ul,
	ul.products {
		margin: 0 0 1em;
		padding: 0;
		list-style: none outside;
		clear: both;
		@include clearfix();

		li {
			list-style: none outside;
		}
	}

	ul.products {
		li.product {

          position: relative;
          text-align: center;
          width: 47%;

          @media(min-width: $screen-sm-min) {
            width: 30.7%;
          }

          .product-inner {
              position: relative;
              width: 100%;
              height: 100%;

              .inner {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: rgba($brand-black,.3);
                transition: .3s;

                div {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;

                    .icon {
                        display: inline-block;
                        padding: 10px 15px;
                        color: $brand-white;
                        background: $brand-primary;

                        &:hover,
                        &:focus{
                            color: $brand-primary;
                            background: $brand-white;
                        }
                    }
                }
              }
            }

            // shadow img and show inner on hover
            &:hover, &:focus{
              img {
                  box-shadow: 0px 10px 24px -7px rgba(0,0,0,0.35);
              }

              .product-inner .inner {
                opacity: 1;
              }
            }

            .add_to_cart_button {
            display: none;
            }

			.onsale {
				top: 10px;
				right: 10px;
				left: auto;
				margin: 0;
			}

			h2.woocommerce-loop-product__title {
				padding: .5em 0;
				margin: 0;
				font-size:  18px;
                font-weight: 700;
                font-family: $font-family-sans-serif;
                margin-top: 15px;
                text-transform: uppercase;
			}

			a {
		      text-decoration: none;

			}

			a img {
				width: auto;
				height: auto;
				display: block;
				margin: 0;
				box-shadow: none;
                transition: all .3s ease-in-out;

			}

			strong {
				display: block;
			}

			.star-rating {
				font-size: .857em;
                margin: 0 auto;
			}

			.button {
				margin-top: 1em;
			}

			.price {
				color: $brand-black;
				display: block;
				font-weight: 300;
				margin-bottom: .5em;
				font-size: 18px;

				del {
					color: inherit;
					opacity: 0.5;
					display: inline-block;
				}

				ins {
					background: none;
					font-weight: 700;
				}

				.from {
					font-size: 0.67em;
					margin: -2px 0 0 0;
					text-transform: uppercase;
					color: rgba(desaturate($highlight, 75%), 0.5);
				}
			}

		}
	}




	.woocommerce-result-count {
		margin: 0 0 50px;
        text-transform: uppercase;
        font-size: 14px;
        color: $brand-black;
	}

	.woocommerce-ordering {
		margin: 0 0 50px;

		select {
          background: $brand-primary;
          color: #fff;
          border: 0 none;
          border-radius: 0 !important;
          padding: 5px 10px;
          vertical-align: top;
          text-transform: uppercase;
          font-size: 14px;
		}
	}

	nav.woocommerce-pagination {
		text-align: left;
        margin-bottom: 30px;

		ul {
			display: inline-block;
			white-space: nowrap;
			padding: 0;
			clear: both;
			border: 0 none;
			border-right: 0;
			margin: 1px;

			li {
				border-right: 0;
				padding: 0;
				margin: 0;
				float: left;
				display: inline;
				overflow: hidden;

				a,
				span {
					margin: 0;
					text-decoration: none;
                    color: $brand-black;
					padding: 0;
					line-height: 1;
					font-size: 24px;
                    font-family: $font-family-sans-serif;
					font-weight: normal;
					padding: 15px 20px;
					min-width: 1em;
					display: block;
				}

				span.current,
				a:hover,
				a:focus {
					background: $brand-primary;
					color: $brand-white;
				}
			}
		}
	}


    /**
       * Product carousel
       */
    #product-carousel {

      .product-inner {
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;

          .inner {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: rgba($brand-black,.3);
            transition: .3s;

            div {
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;

                .icon {
                    display: inline-block;
                    padding: 10px 15px;
                    color: $brand-white;
                    background: $brand-primary;

                    &:hover,
                    &:focus{
                        color: $brand-primary;
                        background: $brand-white;
                    }
                }
            }
          }


          // shadow img and show inner on hover
          &:hover, &:focus{
            img {
                box-shadow: 0px 10px 24px -7px rgba(0,0,0,0.35);
            }

            .inner {
              opacity: 1;
            }
          }
        }
        .add_to_cart_button {
        display: none;
        }

        .onsale {
            top: 10px;
            right: 10px;
            left: auto;
            margin: 0;
        }

        h2.woocommerce-loop-product__title {
            padding: .5em 0;
            margin: 0;
            font-size:  18px;
            margin-top: 15px;
            text-transform: uppercase;
        }

        a {
          text-decoration: none;

        }

        a img {
            width: 100%;
            height: auto;
            display: block;
            margin: 0;
            box-shadow: none;
            transition: all .3s ease-in-out;

        }

        strong {
            display: block;
        }

        .star-rating {
            font-size: .857em;
            margin: 0 auto;
        }

        .button {
            margin-top: 1em;
        }

        .price {
            color: $brand-black;
            text-align: center;
            display: block;
            font-weight: 300;
            margin-bottom: .5em;
            font-size: 18px;
        }

      .owl-dots .owl-dot {
          span {
            width: 50px;
            height: 8px;
            border-radius: 0;
            opacity: 1;
            margin: 0;
            background: $brand-lighter;
            @include transition(.3s);
          }
          &.active span {
            background: $brand-primary;
          }
      }

    }




	/**
	 * Buttons
	 */
	a.button,
	button.button,
	input.button,
	#respond input#submit {

        @extend .btn;
        @extend .btn-primary;
//		font-size: 100%;
//		margin: 0;
//		line-height: 1;
//		cursor: pointer;
//		position: relative;
//		font-family: inherit;
//		text-decoration: none;
//		overflow: visible;
//		padding: .618em 1em;
//		font-weight: 700;
//		border-radius: $border-radius-base;
//		left: auto;
//		color: $secondarytext;
//		background-color: $secondary;
//		border: 0;
//		white-space: nowrap;
//		display: inline-block;
//		background-image: none;
//		box-shadow: none;
//		text-shadow: none;

		&.loading {
			opacity: 0.25;
			padding-right: 2.618em;

			&:after {
				font-family: "WooCommerce";
				content: "\e01c";
				vertical-align: top;
				-webkit-font-smoothing: antialiased;
				font-weight: 400;
				position: absolute;
				top: .618em;
				right: 1em;
			    animation: spin 2s linear infinite;
			}
		}

		&.added {
			&:after {
				font-family: 'WooCommerce';
				content: "\e017";
				margin-left: .53em;
				vertical-align: bottom;
			}
		}

		&:hover {
			background-color: $secondary - #111;
			text-decoration: none;
			background-image: none;
			color: $secondarytext;
		}

		&.alt {
			background-color: $primary;
			color: $primarytext;
			-webkit-font-smoothing: antialiased;

			&:hover {
				background-color: $primary - #111;
				color: $primarytext;
			}

			&.disabled,
			&:disabled,
			&:disabled[disabled],
			&.disabled:hover,
			&:disabled:hover,
			&:disabled[disabled]:hover {
				background-color: $primary;
				color: $primarytext;
			}
		}

		&:disabled,
		&.disabled,
		&:disabled[disabled] {
			color: inherit;
			cursor: not-allowed;
			opacity: 0.5;

			&:hover {
				color: inherit;
				background-color: $secondary;
			}
		}
	}

	.cart .button,
	.cart input.button {
		float: none;
	}

	a.added_to_cart {
		padding-top: .5em;
		white-space: nowrap;
		display: inline-block;
	}

	/**
	 * Reviews
	 */
	#reviews {
		h2 small {
			float: right;
			color: $subtext;
			font-size: 15px;
			margin: 10px 0 0 0;

			a {
				text-decoration: none;
				color: $subtext;
			}
		}

		h3 {
            font-size: 20px;
            font-family: $font-family-sans-serif;
            font-weight: 700;
            margin-bottom: 20px;
        }

		#respond {
			margin: 0;
			border: 0;
			padding: 0;
		}

		#comment {
			height: 75px;
		}

		#comments {
			.add_review {
				@include clearfix();
			}

			h2 {
                font-family: $font-family-sans-serif;
                font-size: 36px;
                font-weight: 700;
				clear: none;
			}

			ol.commentlist {
				@include clearfix();
				margin: 0;
                padding: 0;
				width: 100%;
				background: none;
				list-style: none;

				li {
					padding: 0;
					margin: 0 0 20px;
					border: 0;
					position: relative;
					background: 0;
					border: 0;

					.meta {
						color: $subtext;
						font-size: 0.75em;
					}

					img.avatar {
						float: left;
						position: absolute;
						top: 0;
						left: 0;
						padding: 3px;
						width: 32px;
						height: auto;
						background: transparent;
						border: 0 none;
						margin: 0;
						box-shadow: none;
					}

					.comment-text {
                        position: relative;
                        text-align: left;
						margin: 0 0 0 50px;
						border: 0 none;
						border-radius: $border-radius-base;
                        background: $body-bg;
						padding: 5px;
						@include clearfix();

						p {
							margin: 0 0 1em;
						}

						p.meta {
							font-size: 0.83em;
						}

                        &:before {
                            position: absolute;
                            content: '';
                            border-style: solid;
                            top: 5px;
                            left: -10px;
                            border-width: 10px 10px 10px 0;
                            border-color: transparent $body-bg transparent transparent;
                        }
					}
				}

				ul.children {
					list-style: none outside;
					margin: 20px 0 0 50px;

					.star-rating {
						display: none;
					}
				}

				#respond {
					border: 1px solid darken( $secondary, 3% );
					border-radius: $border-radius-base;
					padding: 1em 1em 0;
					margin: 20px 0 0 50px;



				}
			}

			.commentlist > li:before {
				content: "";
			}
		}
	}

	/**
	 * Star ratings
	 */
	.star-rating {
		float: right;
		overflow: hidden;
		position: relative;
		height: 1em;
		line-height: 1;
		font-size: 1em;
		width: 5.4em;
		font-family: 'star';

		&:before {
			content: "\53\53\53\53\53";
			color: #ddd;
			float: left;
			top: 0;
			left: 0;
			position: absolute;
		}

		span {
			overflow: hidden;
			float: left;
			top: 0;
			left: 0;
			position: absolute;
			padding-top: 1.5em;
		}

		span:before {
			content: "\53\53\53\53\53";
			color: $brand-primary;
			top: 0;
			position: absolute;
			left: 0;
		}
	}

	.woocommerce-product-rating {
		@include clearfix();
		line-height: 2;
		display: block;

		.star-rating {
			margin: .5em 4px 0 0;
			float: left;
		}
        .woocommerce-review-link {
            color: $brand-lighter;
            &:hover, &:focus {
              color: $brand-primary;
            }
        }
	}

	.products {
		.star-rating {
			display: block;
			margin: 0 0 .5em;
			float: none;
		}

      //Related products single page
      &.related {
        h2 {
          position: relative;
          text-align: center;
          font-family: $font-family-sans-serif;
          font-weight: 700;
          font-size: 36px;
          margin-bottom: 50px;

          span {
            color: $brand-primary;
          }
        }
      }
	}

	.hreview-aggregate {
		.star-rating {
			margin: 10px 0 0 0;
		}
	}

	#review_form {
      text-align: left;

		#respond {
			@include clearfix();
			position: static;
			margin: 0;
			width: auto;
			padding: 0 0 0;
			background: transparent none;
			border: 0;

			p {
				margin: 0 0 10px;
			}

			.form-submit {
				input {
					left: auto;
				}
			}

			textarea {
				box-sizing: border-box;
				width: 100%;
			}
		}
	}

	p.stars {
		a {
			position: relative;
			height: 1em;
			width: 1em;
			text-indent: -999em;
			display: inline-block;
			text-decoration: none;

			&:before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 1em;
				height: 1em;
				line-height: 1;
				font-family: "WooCommerce";
				content: "\e021";
				text-indent: 0;
			}

			&:hover {
				~ a:before {
					content: "\e021";
				}
			}
		}

		&:hover {
			a {
				&:before {
					content: "\e020";
				}
			}
		}

		&.selected {
			a.active {
				&:before {
					content: "\e020";
				}

				~ a:before {
					content: "\e021";
				}
			}

			a:not(.active) {
				&:before {
					content: "\e020";
				}
			}
		}
	}


	/**
	 * Tables
	 */
	table.shop_attributes {
		border: 0;
		border-top: 1px dotted rgba(0,0,0,0.1);
		margin-bottom: 1.618em;
		width: 100%;

		th {
			width: 150px;
			font-weight: 700;
			padding: 8px;
			border-top: 0;
			border-bottom: 1px dotted rgba(0,0,0,0.1);
			margin: 0;
			line-height: 1.5;
		}

		td {
			font-style: italic;
			padding: 0;
			border-top: 0;
			border-bottom: 1px dotted rgba(0,0,0,0.1);
			margin: 0;
			line-height: 1.5;

			p {
				margin: 0;
				padding: 8px 0;
			}
		}

		.alt td,
		.alt th {
			background: rgba(0,0,0,0.025);
		}
	}

	table.shop_table {
        color: $brand-black;
		border: 0 none;
		margin: 0px -1px 24px 0px;
		text-align: left;
        font-size: 1em;
		border-collapse: separate;
		border-radius: $border-radius-base;

        .cart_item {
          td {
            font-weight: 400;
          }
        }
        .product-total {
          text-align: right;
        }
		th {
			font-weight: 700;
			padding: 9px 12px;

		}

		td {
			border-top: 0 none;
			padding: 10px;
			vertical-align: middle;

			small {
				font-weight: normal;
			}
		}

		tbody:first-child {
			tr:first-child {
				th,
				td {
					border-top: 0;
				}
			}
		}

		tfoot td,
		tfoot th,
		tbody th {
			border-top: 1px solid rgba(0,0,0,0.1);
		}
      .tfoot th {
		  font-weight: 700;
          text-transform: uppercase;
      }
	}

	table.my_account_orders {
		font-size: 0.85em;

		th,
		td {
			padding: 4px 8px;
			vertical-align: middle;
		}

		.button {
			white-space: nowrap;
		}

		.order-actions {
			text-align: right;

			.button {
				margin: .125em 0 .125em .25em;
			}
		}
	}

	td.product-name {

        a {
          font-weight: 700;
        }
		dl.variation {
			margin: .25em 0;
			@include clearfix();

			dt,
			dd {
				display: inline-block;
				float: left;
				margin-bottom: 1em;
			}

			dt {
				font-weight: 700;
				padding: 0 0 .25em 0;
				margin: 0 4px 0 0;
				clear: left;
			}

			dd {
				padding: 0 0 .25em 0;

				p:last-child {
					margin-bottom: 0;
				}
			}
		}

		p.backorder_notification {
			font-size: 0.83em;
		}
	}

	td.product-quantity {
		min-width: 80px;
        text-align: right;
	}


	/**
	 * Cart sidebar
	 */
	ul.cart_list,
	ul.product_list_widget {
		list-style: none outside;
		padding: 0;
		margin: 0;

		li {
			padding: 4px 0;
			margin: 0;
			@include clearfix();
			list-style: none;

			a {
				display: block;
				font-weight: 700;
			}

			img {
				float: right;
				margin-left: 4px;
				width: 32px;
				height: auto;
				box-shadow: none;
			}

			dl {
				margin: 0;
				padding-left: 1em;
				border-left: 2px solid rgba(0,0,0,0.1);
				@include clearfix();

				dt,
				dd {
					display: inline-block;
					float: left;
					margin-bottom: 1em;
				}

				dt {
					font-weight: 700;
					padding: 0 0 .25em 0;
					margin: 0 4px 0 0;
					clear: left;
				}

				dd {
					padding: 0 0 .25em 0;

					p:last-child {
						margin-bottom: 0;
					}
				}
			}

			.star-rating {
				float: none;
			}
		}
	}

	&.widget_shopping_cart,
	.widget_shopping_cart {
		.total {
			border-top: 3px solid $brand-lighter;
			padding: 5px 0;

			strong {
				min-width: 40px;
				display: inline-block;
			}
		}

		.cart_list {
			li {
				padding-left: 2em;
				position: relative;
				padding-top: 0;

				a.remove {
                    color: $brand-primary !important;
					position: absolute;
                    font-weight: 300;
					top: 0;
					left: 0;
                    &:hover {
                      color: $brand-primary;
                      background: transparent ;
                    }
				}
			}
		}

		.buttons {
			@include clearfix();
		}
	}

	/**
	 * Forms
	 */
	form {
		.form-row {
			padding: 3px;
			margin: 0 0 6px;

			[placeholder]:focus::-webkit-input-placeholder {
				transition: opacity 0.5s 0.5s ease;
				opacity: 0;
			}

			label {
              font-size: .8em;
              font-family: $font-family-sans-serif;
              font-weight: 700;
              text-transform: uppercase;
		      line-height: 2;
			}

			label.hidden {
				visibility: hidden;
			}

			label.inline {
				display: inline;
			}

			select {
				cursor: pointer;
				margin: 0;
			}

			.required {
				color: red;
				font-weight: 700;
				border: 0;
			}

			.input-checkbox {
				display: inline;
				margin: -2px 8px 0 0;
				text-align: center;
				vertical-align: middle;
			}

			input.input-text,
			textarea {
				box-sizing: border-box;
				width: 100%;
				margin: 0;
				outline: 0;
				line-height: 1;
			}

			textarea {
				height: 4em;
				line-height: 1.5;
				display: block;
				box-shadow: none;
			}

			.select2-container--default {

              .select2-selection--single {
                border-color: #ccc;
                box-shadow: none;
                border-radius: 0;
              }
			}

			&.woocommerce-invalid {
				label {
					color: $red;
				}
				.select2-container,
				input.input-text,
				select {
					border-color: #ccc;
				}
			}

			&.woocommerce-validated {
				.select2-container,
				input.input-text,
				select {
					border-color: #ccc;
				}
			}

			::-webkit-input-placeholder {
				line-height: normal;
			}

			:-moz-placeholder {
				line-height: normal;
			}

			:-ms-input-placeholder {
				line-height: normal;
			}
		}
	}

    #customer_login {
      h2 {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        padding-left: 20px;
      }
    }

	form.login,
	form.checkout_coupon,
	form.register {
		border: 1px solid transparent;
		padding: 20px;
		margin: 2em 0 2em 0px;
		text-align: left;
		border-radius: $border-radius-base;
	}

	ul#shipping_method {
		list-style: none outside;
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: .25em 0 .25em 22px;
			text-indent: -22px;
			list-style: none outside;
			input {
				margin: 3px 0.5ex;
			}
			label {
		      display: inline;
              font-weight: 400;
              text-transform: none;
			}
		}

		.amount {
			font-weight: 400;
		}
	}

	p.woocommerce-shipping-contents {
		margin: 0;
	}

	/**
	 * Order page
	 */
	.order_details {
		@include clearfix();
		margin: 0 0 1.5em;
		list-style: none;

		li {
			float: left;
			margin-right: 2em;
			text-transform: uppercase;
			font-size: 0.715em;
			line-height: 1;
			border-right: 1px dashed darken( $secondary, 10% );
			padding-right: 2em;
			margin-left: 0;
			padding-left: 0;

			strong {
				display: block;
				font-size: 1.4em;
				text-transform: none;
				line-height: 1.5;
			}

			&:last-of-type {
				border: none;
			}
		}
	}

//	/**
//	 * Layered nav widget
//	 */
//	.widget_layered_nav {
//		ul {
//			margin: 0;
//			padding: 0;
//			border: 0;
//			list-style: none outside;
//
//			li {
//				@include clearfix();
//				padding: 0 0 1px;
//				list-style: none;
//
//				a,
//				span {
//					padding: 1px 0;
//				}
//			}
//
//			li.chosen {
//				a {
//					&:before {
//						@include iconbefore( "\e013" );
//						color: $red;
//					}
//				}
//			}
//		}
//	}

//	.widget_layered_nav_filters {
//		ul {
//			margin: 0;
//			padding: 0;
//			border: 0;
//			list-style: none outside;
//			overflow: hidden;
//			zoom: 1;
//
//			li {
//				float: left;
//				padding: 0 1px 1px 0;
//				list-style: none;
//
//				a {
//					text-decoration: none;
//
//					&:before {
//						@include iconbefore( "\e013" );
//						color: $red;
//					}
//				}
//			}
//		}
//	}

	/**
	 * Price filter widget
	 */
	.widget_price_filter {
		.price_slider {
			margin-bottom: 1em;
		}

		.price_slider_amount {
			text-align: right;
			line-height: 2.4;
			font-size: 0.8751em;

			.button {
				font-size: 1.15em;
			}

			.button {
				float: left;
			}
		}

		.ui-slider {
			position: relative;
			text-align: left;
			margin-left: .5em;
			margin-right: .5em;
		}

		.ui-slider .ui-slider-handle {
			position: absolute;
			z-index: 2;
			width: 1em;
			height: 1em;
			background-color: $primary;
			border-radius: 1em;
			cursor: ew-resize;
			outline: none;
			top: -.3em;

			//&:last-child {
				margin-left: -.5em;
			//}
		}

		.ui-slider .ui-slider-range {
			position: absolute;
			z-index: 1;
			font-size: .7em;
			display: block;
			border: 0;
			border-radius: 1em;
			background-color: $primary;
		}

		.price_slider_wrapper .ui-widget-content {
			border-radius: 1em;
			background-color: $primary - #444;
			border: 0;
		}

		.ui-slider-horizontal {
			height: .5em;
		}

		.ui-slider-horizontal .ui-slider-range {
			top: 0;
			height: 100%;
		}

		.ui-slider-horizontal .ui-slider-range-min {
			left: -1px;
		}

		.ui-slider-horizontal .ui-slider-range-max {
			right: -1px;
		}
	}

	/**
	 * Rating Filter Widget
	 */
//	.widget_rating_filter {
//		ul {
//			margin: 0;
//			padding: 0;
//			border: 0;
//			list-style: none outside;
//
//			li {
//				@include clearfix();
//				padding: 0 0 1px;
//				list-style: none;
//
//				a {
//					padding: 1px 0;
//					text-decoration: none;
//				}
//
//				.star-rating {
//					float: none;
//					display: inline-block;
//				}
//			}
//
//			li.chosen {
//				a {
//					&:before {
//						@include iconbefore( "\e013" );
//						color: $red;
//					}
//				}
//			}
//		}
//	}
}

/**
 * Account page
 */
.woocommerce-account {
	.woocommerce {
		@include clearfix();
	}

	.my-account-navigation {
		float: left;
		width: 30%;
	}

	.my-account-content {
		float: right;
		width: 68%;
	}

	.addresses {
		.title {
			@include clearfix();

			h3 {
				float: left;
			}

			.edit {
				float: right;
			}
		}
	}

	ol.commentlist.notes {
		li.note {

			p.meta {
				font-weight: 700;
				margin-bottom: 0;
			}

			.description {
				p:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
//	ul.digital-downloads {
//		margin-left: 0;
//		padding-left: 0;
//
//		li {
//			list-style: none;
//			margin-left: 0;
//			padding-left: 0;
//
//			&:before {
//				@include iconbefore( "\e00a" );
//			}
//
//			.count {
//				float: right;
//			}
//		}
//	}
}

/**
 * Cart page
 */
.woocommerce-cart {
	table.cart {
		.product-thumbnail {
			min-width: 32px;
		}

		img {
			width: 80px;
			box-shadow: none;
		}

		th,
		td {
			vertical-align: middle;
		}

		td.actions {
          padding-bottom: 20px;
			.coupon {
				.input-text {
					float: left;
					box-sizing: border-box;
					//border: 1px solid $brand-primary;
					padding: 6px 6px 5px;
					margin: 0 4px 0 0;
					outline: 0;
                    width: auto;
                    height: 36px;
                    width: 110px !important;
					line-height: 1;
				}
			}
		}

		input {
			margin: 0;
			vertical-align: middle;
			line-height: 1;
		}
	}

	.wc-proceed-to-checkout {
		@include clearfix;
		padding: 1em 0;

		a.checkout-button {
			display: block;
			text-align: center;
			margin-bottom: 0;
			font-size: 18px;
			padding: 5px 10px;
		}
	}

	.cart-collaterals {
//		.shipping_calculator {
//			.button {
//				width: 100%;
//				float: none;
//				display: block;
//			}
//
//			.shipping-calculator-button {
//				&:after {
//					@include iconafter( "\e019" );
//				}
//			}
//		}
        h2 {
          font-family: $font-family-sans-serif;
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          padding-bottom: 20px;
          border-bottom: 2px solid #ddd;
        }

		.cart_totals {
            width: 100%;
            float: none;
            background: #efefef;
            padding: 30px;
            color: $brand-black;
            font-size: 14px;
            text-transform: uppercase;
//			p {
//				small {
//					color: $subtext;
//					font-size: 0.83em;
//				}
//			}
//
//			table {
//				border-collapse: separate;
//				margin: 0 0 6px;
//				padding: 0;
//
//				tr:first-child {
//					th,
//					td {
//						border-top: 0;
//					}
//				}
//
//				th {
//					width: 40%;
//				}
//
//				td,
//				th {
//					vertical-align: top;
//					border-left: 0;
//					border-right: 0;
//					line-height: 1.5em;
//				}
//
//				small {
//					color: $subtext;
//				}
//
//				select {
//					width: 100%;
//				}
//			}
//
//			.discount td {
//				color: $highlight;
//			}
//
			tr td,
			tr th {
				border-top: 2px solid #ddd;
			}
		}

//		.cross-sells {
//			ul.products {
//				li.product {
//					margin-top: 0;
//				}
//			}
//		}
	}
}

/**
 * Checkout
 */
.woocommerce-checkout, #add_payment_method {


	.checkout {

      .order-summary {
        background: #efefef;
        color: $brand-black;
        font-size: 14px;
        font-weight: 700;
        font-family: $font-family-sans-serif;
        padding: 30px;

        h3#order_review_heading {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          font-family: $font-family-sans-serif;
        }

      }
      h3 {
        font-size: 36px;
        font-weight: 700;
        font-family: $font-family-sans-serif;

      }
      .col-1 {
        float: none;
        width: 100%;
      }
      .col-2 {
          float: none;
          width: 100%;

			h3#ship-to-different-address {
				float: left;
				clear: none;

              label {
                font-weight: 400;

                span {
                  font-size: 36px;
                  font-weight: 700;
                  text-transform: initial;
                  font-family: $font-family-sans-serif;
                }
              }
			}

			.notes {
				clear: left;
			}

			.form-row-first {
				clear: left;
			}
		}

		.create-account small {
			font-size: 11px;
			color: $subtext;
			font-weight: normal;
		}

		div.shipping-address {
			padding: 0;
			clear: left;
			width: 100%;
		}

		.shipping_address {
			clear: both;
		}
	}

    .woocommerce-account-fields {
      .create-account {

        label {
          input {
            margin: 8px 0;
          }
          span {
            padding-left: 20px;
          }
        }
      }
    }

	#payment {
		background: transparent;
        color: $text-color;
		border-radius: $border-radius-base;

		ul.payment_methods {
			@include clearfix();
			text-align: left;
			padding: 1em;
			border-bottom: 1px solid lighten($text-color,60%);
			margin: 0;
			list-style: none outside;

			li {
				line-height: 2;
				text-align: left;
				margin: 0;
				font-weight: normal;
				@include clearfix;

				input {
					margin: 0 1em 0 0;
				}

				img {
					vertical-align: middle;
					margin: -2px 0 0 .5em;
					padding: 0;
					position: relative;
					box-shadow: none;
				}

				img + img {
					margin-left: 2px;
				}
			}
		}

		div.form-row {
			padding: 1em;
		}

		div.payment_box {
			position: relative;
			box-sizing: border-box;
			width: 100%;
			padding: 1em;
			margin: 1em 0 1em 0;
			font-size: 0.92em;
			border-radius: 2px;
			line-height: 1.5;
			background-color: $brand-black;
			color: #fff;

			input.input-text, textarea {
				border-color: darken( $secondary, 15% );
				border-top-color: darken( $secondary, 20% );
			}

			::-webkit-input-placeholder {
				color: darken( $secondary, 20% );
			}

			:-moz-placeholder {
				color: darken( $secondary, 20% );
			}

			:-ms-input-placeholder {
				color: darken( $secondary, 20% );
			}

			.wc-credit-card-form-card-number,
			.wc-credit-card-form-card-expiry,
			.wc-credit-card-form-card-cvc {
				font-size: 1.5em;
				padding: 8px;
				background-repeat: no-repeat;
				background-position: right .618em center;
				background-size: 32px 20px;

				&.visa {
					background-image: url(../images/icons/credit-cards/visa.svg);
				}

				&.mastercard {
					background-image: url(../images/icons/credit-cards/mastercard.svg);
				}

				&.laser {
					background-image: url(../images/icons/credit-cards/laser.svg);
				}

				&.dinersclub {
					background-image: url(../images/icons/credit-cards/diners.svg);
				}

				&.maestro {
					background-image: url(../images/icons/credit-cards/maestro.svg);
				}

				&.jcb {
					background-image: url(../images/icons/credit-cards/jcb.svg);
				}

				&.amex {
					background-image: url(../images/icons/credit-cards/amex.svg);
				}
				&.discover {
					background-image: url(../images/icons/credit-cards/discover.svg);
				}
			}

			span.help {
				font-size: .857em;
				color: $subtext;
				font-weight: normal;
			}

			.form-row {
				margin: 0 0 1em;
			}

			p:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: "";
				display: block;
				border: 1em solid $brand-black; /* arrow size / color */
				border-right-color: transparent;
				border-left-color: transparent;
				border-top-color: transparent;
				position: absolute;
				top: -.75em;
				left: 0;
				margin: -1em 0 0 2em;
			}
		}

		.payment_method_paypal {
			.about_paypal {
				float: right;
				line-height: 2;
				font-size: 0.83em;
                font-style: italic;
                margin-left: 10px;
			}

			img {
				max-height: 52px;
				vertical-align: middle;
			}
		}
	}




}


// My Account
.woocommerce-account {

  .woocommerce-MyAccount-navigation {
      float: none;
      width: 80%;
      margin: 0 auto;
      text-align: center;

      ul {
        display: inline-block;
        list-style: none;
        padding: 0;

        li {
          display: inline-block;

          a {
            padding: 10px 15px;
            color: #ccc;
            transition: .3s;
          }

          &.is-active {
            a {
              color: $brand-primary;
              border-bottom: 3px solid $brand-primary;
            }
          }
        }
      }

  }
  .woocommerce-MyAccount-content {
    padding: 50px 0 0;
    float: none;
    width: 80%;
    margin: 0 auto;

    fieldset {
      margin-top: 30px;
    }

  }
}


// PrettyPhoto
.pp_woocommerce {
  .ppt {
    display: none !important;
  }
  .pp_content_container  {
    background: transparent !important;
    box-shadow: none !important;
  }
}

// Select2
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $brand-primary !important;
  color: #fff !important;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $brand-lighter !important;
}
