#footer {
  clear: both;
  position: relative;
  background: url('../images/lechocolat-hero-bg.jpg') no-repeat;
  background-size: cover;
  background-position: 0 0;

  & > div {
    padding: 30px 0;
    z-index: 2;
  }

  .footer-menu {

    ul {
      @extend .list-inline;

      li {

        border-right: 2px solid #fff;
        padding: 0 10px;

        &:last-child {
          border-right: 0 none;
        }

        a {
          font-family: $headings-font-family;
          font-size: 15px;
          color: $brand-white;
          text-transform: uppercase;
        }
      }
    }
  }
  .footer-branding {
    background: url('../images/kraft-bg.jpg');
    box-shadow: 0px 0px 100px rgba(0,0,0,.8);

    #logo {
        display: block;
		margin-bottom: 30px;
		padding: 0;

      a {
        display: inline-block;
        width: 150px;
        height: 82px;
        text-indent: -9999px;
        background: url('../images/lechocolat-logo.png');
        background-size: 150px 82px;
        background-repeat: no-repeat;
        transform: rotate(-5deg);
        text-transform: uppercase;

        @include transition(.4s);

        &:hover, &:focus {
          transform: rotate(-3deg) scale(1.1);
        }
      }
	}
  }
  .footer-copyright {
    color: $brand-white;
  }
}

/* ==========================================================================
   Social icons
   ========================================================================== */
.social-icons {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		@include inline-block;
		a {
			@include inline-block;
			color: $brand-black;
			padding-left: 10px;
			padding-right: 10px;
			i {
				font-size: 20px;
			}
		}
	}
}
