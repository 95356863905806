#page {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;

	@include transition(.5s);

	.offcanvas & {
		overflow: hidden;
		position: absolute;

		&:after {
			@include transition(2s);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 101;
			background: rgba(0,0,0,.7);
			content: "";
		}
	}
}

#content {
    background: $brand-white;
//    background: url('../images/wood-bg.jpg') no-repeat;
//    background-size: 100% auto;
//    background-position: bottom center;
}


.section {
    position: relative;
	padding: 7em 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 0;

	@media screen and (max-width: $screen-xs) {
		padding: 3em 0;
	}

    h2, h3, h4 {
      font-family: $font-family-sans-serif;
      font-weight: 700;
    }

    .overlay {
		z-index: 0;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .7);
	}

}

.bg-section {
  background: url('../images/wood-bg.jpg') no-repeat;
  background-size: 100% auto;
  background-position: bottom center;
}

.page-header {
  position: relative;
  height: 350px;
  margin: 0;
  border-bottom: 0 none;


  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: 0;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    z-index: 1;
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    margin: 0;
    background: rgba($brand-secondary,0.7);
  }

  .header {
    position: absolute;
    width: 100%;
    top: 60%;
    color: #fff;
    transform: translateY(-50%);
    text-align: center;
    z-index: 3;

    h1 {
      color: $brand-white;
      font-size: 54px;
      text-transform: uppercase;
      margin: 10px;
    }

    .breadcrumbs {
      color: $brand-white;
      text-transform: uppercase;
      font-family: $headings-font-family;
      font-size: 15px;

      a {
        color: $brand-white;
      }

      .current {
        opacity: .5;
      }

    }
  }
}



/* ==========================================================================
   Off Canvas
   ========================================================================== */
#offcanvas {
	position: absolute;
	z-index: 1901;
	width: 270px;
	background: lighten($brand-black, 0%);
	top: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 45px 40px 40px 40px;
	overflow-y: auto;
	display: none;
	@include translateX(270px);
	@include transition(.5s);
	@media screen and(max-width: $screen-sm){
		display: block;
	}
	.offcanvas & {
		@include translateX(0px);
	}
	a {
		color: rgba(255,255,255,.5);
		&:hover {
			color: rgba(255,255,255,.8);
		}
	}
	ul {
		padding: 0;
		margin: 0;
        margin-bottom: 50px;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
			&.search{
				float: left;
				width: 100%;
				font-family: $font-secondary;
				@include transition(.3s);
				padding: 0;
				margin: 5px 0 0 0;

			}
			input[type=text]{
				padding: 6px 10px;
				width: 100%;
				border: 1px solid rgba($brand-white,.1);
				float: right;
				font-size: 13px;
				margin-top: 4px;
				background: transparent;
				@include transition(.3s);
				&:focus{
					width: 100%;
					outline: none;
				}
			}
			button{
				margin-top: 4px;
				padding: 6px;
				background: rgba($brand-white,.1);
				border: 1px solid rgba($brand-white,.1);
				margin-left: 1px;
				i{
					color: rgba($brand-white,.5);
				}
				&:hover, &:focus{
					background: transparent !important;
					i{
						color: rgba($brand-white,1);
					}
				}
			}

			a{
				&.cart{
					display: block;
					margin-top: 30px;
					color: rgba(255,255,255,.5);
					span{
						position: relative;
						small{
							position: absolute;
							top: -20px;
							right: -15px;
							padding: 10px 6px;
							line-height: 0;
							font-size: 11px;
							background: $brand-primary;
							color: rgba(255,255,255,1);
							border-radius: 50%;
						}
					}
				}
			}
			> ul {
				padding-left: 20px;
				display: none;
			}
			&.offcanvas-has-dropdown {
				> a {
					display: block;
					position: relative;
					&:after {
						position: absolute;
						right: 0px;
						font-family: 'icomoon';
						content: "\e921";
						font-size: 20px;
						color: rgba(255,255,255,.2);
						@include transition(.5s);
					}
				}
				&.active {
					a {
						&:after {
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
	}

  .widget_shopping_cart {
    .remove {
      left: -25px;
    }
    .buttons {
      a {
        display: block;
        background-color: lighten($brand-black, 5%);
        border-color: lighten($brand-black, 5%);
        &:hover,&:focus {
          background-color: lighten($brand-black, 10%);
          border-color: lighten($brand-black, 10%);
        }
      }
    }
  }
}

/* ==========================================================================
   Headings
   ========================================================================== */
.heading {
	margin-bottom: 5em;
	&.heading-sm {
		margin-bottom: 2em;
	}
	h2 {
		font-size: 36px;
		margin-bottom: 20px;
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: $brand-black;
	}
	p {
		font-size: 18px;
		line-height: 1.5;
		color: #828282;
	}
	span{
		display: block;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
		font-family: $font-primary;
	}
}


/* ==========================================================================
   Hero
   ========================================================================== */

#hero {
    position: relative;
    overflow: hidden;
//	min-height: 700px;
    height: 100vh;
	background: url(../images/lechocolat-hero-bg.jpg) no-repeat;
    background-size: cover;
    background-position: 0 0;

  .banner {
    background: url(../images/kraft-bg.jpg) center center;
    box-shadow: 0px 0px 30px rgba(0,0,0,0.8);
    position: absolute;
    padding: 80px 0;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    #logo {
      margin-bottom: 30px;

      @media(min-width: $screen-sm-min) {
        margin-bottom: 50px;
      }

      @media(min-width: $screen-md-min) {
        margin-bottom: 80px;
      }

      a {
        display: inline-block;
        background: url(../images/lechocolat-logo.png) center center;
        background-size: 200px 113px;
        width: 200px;
        height: 113px;
        text-indent: -9999px;
        transform: rotate(-5deg);
        @include transition(.3s);

        @media(min-width: $screen-sm-min) {
          background-size: 300px 170px;
          width: 300px;
          height: 170px;
        }

        @media(min-width: $screen-md-min) {
          background-size: 400px 227px;
          width: 400px;
          height: 227px;
        }

        &:hover,
        &:focus {
          transform: rotate(-3deg) scale(1.1);
        }

      }
    }

    .menu-1 {
      display: inline-block;

      ul {
        display: inline-block;
        list-style: none;

        li {
          display: inline-block;
          margin: 0 10px;

          a {
            display: inline-block;
            font-family: $headings-font-family;
            font-size: 15px;
            font-weight: 800;
            padding: 5px 10px;
            border: 3px solid $brand-black;
            border-radius: 10px;
            color: $brand-black;
            text-transform: uppercase;

            @include transition(.4s);

            &:hover,
            &:focus {
              color: $brand-primary;
              border-color: $brand-primary;
            }
          }


        &:nth-child(odd) a { transform: rotate(2deg) ; }
        &:nth-child(even) a { transform: rotate(-2deg) ; }
        &:last-child a { transform: rotate(-1deg) ; }

        }
      }
    }
    .menu-2 {
      display: inline-block;

      ul {
        display: inline-block;
        list-style: none;
        padding: 0;

        li {
          display: inline-block;

          .dropdown {
            display: none;
          }

          a {
              &.cart {
                color: $brand-black;

                span {
                  position: relative;
                  small{
                      position: absolute;
                      top: 0px;
                      right: -25px;
                      padding: 10px 6px;
                      line-height: 0;
                      font-size: 11px;
                      background: $brand-primary;
                      color: rgba(255,255,255,1);
                      border-radius: 50%;
                  }
                }
              }
			}
          }
        }

      }

    }
}

/* ==========================================================================
   Selection
   ========================================================================== */
#selection {

  #selection-carousel {
     margin-bottom: 30px;

      .item {
          padding-left: 10px;
          padding-right: 10px;
      	  margin-bottom: 20px;
          position: relative;

          .item-inner {
              background: $brand-white;
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              cursor: pointer;
              overflow: hidden;

              &:after {
                  position: absolute;
                  content: "";
                  height: 100%;
                  width: 100%;
                  top: 0;
                  left: 0;
                  z-index: 12;
                  box-shadow: inset 0px 0px 600px 0px rgba(0,0,0,0.9);
                  @include transition(.3s)
              }
              img {
                  position: relative;
                  z-index: 10;
                  @include transition(.3s);
              }
              .caption {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%,-50%);
                  width: 90%;
                  z-index: 15;
                  font-size: 16px;
                  text-align: center;

                  h3 {
                      display: inline-block;
                      font-family: $headings-font-family;
                      color: $brand-white;
                      margin: 0;
                      font-size: 24px;
                      font-weight: 800;
                      border: 4px solid $brand-white;
                      padding: 5px 10px;
                      border-radius: 10px;
                      transform: rotate(-3deg);
                      @include transition(.3s);

                  }

              }
              &:hover {
                  img {
                      transform: scale(1.1);
                  }
                  &:after {
                      box-shadow: inset 0px -126px 600px -69px rgba(0,0,0,0.95);
                  }
              }
          }
      }

      .owl-dots .owl-dot {
          span {
            width: 50px;
            height: 8px;
            border-radius: 0;
            opacity: 1;
            margin: 0;
            background: $brand-lighter;
            @include transition(.3s);
          }
          &.active span {
            background: $brand-primary;
          }
      }
  }
}

/* ==========================================================================
   Blog
   ========================================================================== */
#blog {

	.item {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 20px;
		position: relative;

		.item-inner {
			background: $brand-white;
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			cursor: pointer;
			overflow: hidden;

			&:after {
				position: absolute;
				content: "";
				height: 100%;
				width: 100%;
				top: 0;
                left: 0;
				z-index: 12;
                box-shadow: inset 0px 0px 600px 0px rgba(0,0,0,0.9);
				@include transition(.3s)
			}
			img {
				position: relative;
				z-index: 10;
				@include transition(.3s);
			}
			.caption {
				position: absolute;
				left: 50%;
				top: 50%;
                transform: translate(-50%,-50%);
                width: 90%;
				z-index: 15;
				font-size: 16px;
                text-align: center;

				h3 {
					color: $brand-white;
					font-size: 26px;
                    margin-bottom: 10px;
					@include transition(.3s);

				}
                .category {
				  color: $brand-white;
                  text-transform: uppercase;
                  font-weight: 800;
                  padding-bottom: 20px;
                  position: relative;
                  text-align: center;
				  @include transition(.3s);

                  &:after {
                    position: absolute;
                    content: "";
                    bottom: 10px;
                    width: 30px;
                    height: 2px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $brand-white;
                  }
                }
                .date {
				  color: $brand-white;
                  text-transform: uppercase;
                  font-weight: 800;
                }

			}
			&:hover {
				img {
					transform: scale(1.1);
				}
				&:after {
					box-shadow: inset 0px -126px 311px -69px rgba(0,0,0,0.95);
				}
				.caption {
                    .category {
                      margin-bottom: 5px;
                    }
					h3 {
						margin-top: 5px;
					}
				}
			}
		}

	}
}

/* ==========================================================================
   Intro
   ========================================================================== */
#intro {
  background: $brand-primary;
  color: $brand-white;

  .intro {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 !important;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($brand-secondary,0.7);
      z-index: 1
    }
    .intro-inner {
      position: relative;
      padding: 80px;
      color: $brand-white;
      z-index: 2;

      @media ($screen-md-min) {
        top: 50%;
        transform: translateY(-50%);
      }

      .btn-outline {
        border-color: $brand-white;
        color: $brand-white;
      }

      h3 {
        color: $brand-white;
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 50px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/* ==========================================================================
   Newsletter
   ========================================================================== */
#newsletter {
	background: rgba($brand-primary,1);

	.heading {
		h2 {
			color: $brand-white;
			margin-bottom: 20px !important;
		}
		p{
			color: rgba($brand-white,.6);
		}
	}

	.form-control {
		background: rgba(255,255,255,.2);
		border: none!important;
		color: $brand-white;
		font-size: 16px!important;
		width: 100%;


		&::-webkit-input-placeholder {
		   color: $brand-white;
		}

		&:-moz-placeholder { /* Firefox 18- */
		   color: $brand-white;
		}

		&::-moz-placeholder {  /* Firefox 19+ */
		   color: $brand-white;
		}

		&:-ms-input-placeholder {
		   color: $brand-white;
		}
		@include transition(.5s);
		&:focus {
			background: rgba(255,255,255,.3);
		}

	}
	.btn {
		height: 54px;
		border: none!important;
		background: rgba($brand-black,.8);
		color: $brand-white;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		padding-left: 50px;
		padding-right: 50px;
	}
	.form-inline {
		.form-group {
			width: 100%!important;
			margin-bottom: 10px;
			.form-control {
				width: 100%;
			}
		}
	}
}

/* ==========================================================================
   Login modal
   ========================================================================== */
.login-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	overflow: hidden;
    backface-visibility: hidden;
	visibility: visible;
	outline: none;
	background: #fff;
	z-index: -1;
}

.login-modal .modal-header {
	transition: 0.5s;
	transition-delay: 0.5s;
	border-bottom: 0;
	padding: 40px;
	overflow: hidden;
	position: relative;
	opacity: 0;
	background: #fff;
}

.login-modal .modal-header .close-modal {
	position: absolute;
	right: 40px;
	top: 50%;
    transform: translateY(-50%);
}

.login-modal .modal-header h2 {
	font-size: 32px;
	font-size: 3.2rem;
	font-family: $font-family-sans-serif;
	color: $brand-black;
	font-weight: 300;
	margin: 0;
	line-height: 1;
}

.login-modal .modal-content {
	transition: opacity 0.5s;
    transition-delay: 0.5s;
	box-shadow: none;
	border: 0;
	opacity: 0;
	padding-top: 90px;
	border-radius: 0;
}

.login-modal .close-modal {
	line-height: 1;
}

.login-modal.open {
	visibility: visible;
	z-index: 2000;
}

.login-modal.open .modal-header {
	opacity: 1;
}

.login-modal.open .modal-content {
	opacity: 1;
}


/* ==========================================================================
   Resellers
   ========================================================================== */
#resellers {
  padding-bottom: 7em;

  .reseller {
    .reseller-map {
      .gmap {
        height: 200px;
      }
    }
    .reseller-content {
      text-align: center;
      padding: 30px;
      background-color: $brand-lighter;
    }
  }
}

